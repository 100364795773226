<template>
    <div>
        <div v-if="isAppLoaded">
            <v-progress-linear indeterminate/>
        </div>
        <div v-else>
            {{ $t('globals.redirect') }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'LoadingView',
    props: {
        redirect: {
            type: Object,
            default: () => ({ name: 'home' }),
        },
    },
    created() {
        document.title = this.$t('globals.loading')
    },
    methods: {
        nextRoute() {
            this.$router.push(this.redirect)
        },
    },
    computed: {
        isAppLoaded() {
            let value = this.$store.getters['app/isLoaded']

            if (value) {
                this.nextRoute()
                return true
            }

            return value
        },
    },
}
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isAppLoaded
      ? _c(
          "div",
          [_c("v-progress-linear", { attrs: { indeterminate: "" } })],
          1
        )
      : _c("div", [_vm._v(" " + _vm._s(_vm.$t("globals.redirect")) + " ")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }